import React from "react";
import styles from "./status-filter.module.scss";

export default function StatusFilter(props) {
    const { handleChange, value, options } = props;

    return (
        <div className={styles.statusFilter}>
            <div className={styles.statusFilterLabel}>Status:</div>
            <div className={styles.statusFilterOptions}>
                {options.map(d => (
                    <div
                        className={styles.statusFilterItem}
                        key={d.value}
                        onClick={() => handleChange(d.value)}
                        data-active={value.map(e => e.value).includes(d.value) ? "active" : "inactive"}
                    >
                        <label htmlFor={d.value} className={styles.label} data-status={d.value}>
                            {d.value}
                        </label>
                        <input
                            id={d.value}
                            name={d.value}
                            type="checkbox"
                            checked={value.map(e => e.value).includes(d.value)}
                            onChange={() => handleChange(d.value)}
                            className={styles.input}
                            aria-label={`Turn the status ${d.value} ${
                                value.map(e => e.value).includes(d.value) ? "off" : "on"
                            }`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
