import React from "react";
import styles from "./summary.module.scss";
import Recommendations from "./recommendations/recommendations";
import Report from "./report/report";
import SummaryDropdown from "./summary-dropdown/summary-dropdown";

export default function Summary(props) {
    const { summaryData, auditYear, auditYearOptions, statusOptions, handleChangeStatus, status, tooltips } = props;
    const { byStatus, byEntityType, byCategory } = summaryData;

    return (
        <React.Fragment>
            <div className={styles.summaryHeading} id="qao-recommendations-dashboard-summary">
                <h2 className={styles.summaryHeadingH2}>Summary of recommendations</h2>
            </div>
            <div className={styles.summaryContainer}>
                <Recommendations byStatus={byStatus} auditYear={auditYear} tooltips={tooltips} />
                <Report
                    byEntityType={byEntityType}
                    byCategory={byCategory}
                    auditYear={auditYear}
                    statusOptions={statusOptions}
                    handleChangeStatus={handleChangeStatus}
                    status={status}
                    tooltips={tooltips}
                />
            </div>
        </React.Fragment>
    );
}
