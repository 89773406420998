import React from "react";
import styles from "./explore.module.scss";
import ViewFilter from "./view-filter/view-filter";
import List from "./list/list";

const AUDIT_YEAR_ALL = "All audit years";

export default function Explore(props) {
    const {
        filteredRecommendations,
        selectedEntityType,
        handleSelectEntityType,
        filtersApplied,
        filterAuditYear,
        filterCategories,
        filterReports,
        filterParliamentaryCommittees,
        filterDepartments,
        filterHospitalAndHealthServices,
        filterOtherStateEntities,
        filterCouncils,
        auditYearOptions,
        statusOptions,
        categoriesOptions,
        reportsOptions,
        parliamentaryCommitteesOptions,
        departmentsOptions,
        hospitalAndHealthServicesOptions,
        otherStateEntitiesOptions,
        councilsOptions,
        handleFilterChange,
        handleResetFilters,
        filterStatus,
        handleFilterStatusChange,
        tooltips,
        auditYear,
    } = props;

    return (
        <React.Fragment>
            <h2
                className={styles.exploreHeading}
                id="qao-recommendations-dashboard-explore"
                style={{ marginBottom: auditYear !== "All audit years" ? 0 : "10px" }}
            >
                Explore all recommendations
            </h2>
            {auditYear !== "All audit years" && <small className={styles.tabledIn}>tabled in {auditYear}</small>}
            <ViewFilter
                selectedEntityType={selectedEntityType}
                handleSelectEntityType={handleSelectEntityType}
                filtersApplied={filtersApplied}
                filterAuditYear={filterAuditYear}
                filterCategories={filterCategories}
                filterReports={filterReports}
                filterParliamentaryCommittees={filterParliamentaryCommittees}
                filterDepartments={filterDepartments}
                filterHospitalAndHealthServices={filterHospitalAndHealthServices}
                filterOtherStateEntities={filterOtherStateEntities}
                filterCouncils={filterCouncils}
                auditYearOptions={auditYearOptions.filter(d => d.label !== AUDIT_YEAR_ALL)}
                statusOptions={statusOptions}
                categoriesOptions={categoriesOptions}
                reportsOptions={reportsOptions}
                parliamentaryCommitteesOptions={parliamentaryCommitteesOptions}
                departmentsOptions={departmentsOptions}
                hospitalAndHealthServicesOptions={hospitalAndHealthServicesOptions}
                otherStateEntitiesOptions={otherStateEntitiesOptions}
                councilsOptions={councilsOptions}
                handleFilterChange={handleFilterChange}
                handleResetFilters={handleResetFilters}
                filterStatus={filterStatus}
                handleFilterStatusChange={handleFilterStatusChange}
            />
            <List
                selectedEntityType={selectedEntityType}
                tooltips={tooltips}
                filteredRecommendations={filteredRecommendations}
                filtersApplied={filtersApplied}
                handleResetFilters={handleResetFilters}
            />
        </React.Fragment>
    );
}
