import React from "react";
import styles from "./filter-dropdown.module.scss";
import { useIsMobile } from "../../../../../hooks/mobile.js";
import Select from "react-select";

export default function FilterDropdown(props) {
    const { value, label, handleChange, placeholder, options, type } = props;
    const isMobile = useIsMobile();

    return (
        <div className={styles.filterDropdown}>
            <span className={styles.label} id={`aria-label-${label}`}>
                {label}:
            </span>
            <Select
                aria-labelledby={`aria-label-${label}`}
                options={options}
                onChange={f => handleChange(f, type)}
                placeholder={isMobile ? placeholder.slice(0, 18) + "..." : placeholder}
                isMulti={true}
                value={value}
                styles={{
                    control: provided => ({
                        ...provided,
                        borderColor: "#E3E4E6",
                    }),
                    container: provided => ({
                        ...provided,
                        width: "100%",
                        flexGrow: "1",
                    }),
                    multiValue: provided => ({
                        ...provided,
                        background: "#363E7C",
                        color: "#fff",
                    }),
                    multiValueLabel: provided => ({
                        ...provided,
                        color: "#fff",
                    }),
                    placeholder: provided => ({
                        ...provided,
                        fontSize: isMobile ? "14px" : "16px",
                    }),
                }}
            />
        </div>
    );
}
