import React from "react";
import ReactGA from "react-ga";
import styles from "./item.module.scss";
import { useIsMobile } from "../../../../../hooks/mobile.js";
import ReactTooltip from "react-tooltip";

ReactGA.initialize("UA-12084678-1");

export default function Item(props) {
    const { recommendation, tooltips, selectedEntityType } = props;
    const [expanded, setExpanded] = React.useState(false);
    const isMobile = useIsMobile();

    const handleExpand = () => {
        ReactGA.event({
            category: "RECS: explore",
            action: expanded ? "closed" : "expanded",
            label: recommendation.id,
        });
        setExpanded(!expanded);
    };

    return (
        <div className={styles.item} data-expanded={expanded}>
            <div className={styles.recommendationPanel}>
                <div className={styles.recommendationNumber}>{recommendation.number}</div>
                <div className={styles.recommendation}>
                    <div className={styles.label}>Recommendation {isMobile ? "for " + recommendation.entity : ""}:</div>
                    <div className={styles.recommendationText}>{recommendation.recommendation}</div>
                    <div className={styles.details}>
                        <div className={styles.detailItem}>
                            <div className={styles.label}>Parliamentary committee:</div>
                            <div className={styles.value}>{recommendation.parliamentaryCommittee}</div>
                        </div>
                        <div className={styles.detailItem}>
                            <div className={styles.label}>Report tabled:</div>
                            <div className={styles.value}>{recommendation.auditYear}</div>
                        </div>
                        <div className={styles.detailItem}>
                            <div className={styles.label}>Category:</div>
                            <div className={styles.value}>{recommendation.categories.join(", ")}</div>
                        </div>
                        {selectedEntityType !== "report" && (
                            <div className={styles.detailItem}>
                                <div className={styles.label}>Report:</div>
                                <div className={styles.value}>{recommendation.report}</div>
                            </div>
                        )}
                    </div>
                </div>
                {isMobile && (
                    <div className={styles.mobileStatusSquare} data-status={recommendation.status}>
                        {recommendation.status}
                    </div>
                )}
            </div>
            <div className={styles.responsePanel}>
                <div className={styles.statusContainer}>
                    <div className={styles.status} data-status={recommendation.status}>
                        {recommendation.status}
                    </div>
                </div>
                <div className={styles.detailItem}>
                    <div className={styles.label}>Entity:</div>
                    <div
                        className={styles.value}
                        data-tip={recommendation.entity}
                        data-for={recommendation.entity}
                        data-unnamed={recommendation.entity.startsWith("Entity") ? "true" : "false"}
                    >
                        {recommendation.entity}
                    </div>
                    {tooltips && recommendation.entity.startsWith("Entity") && (
                        <ReactTooltip
                            aria-haspopup="true"
                            type="light"
                            id={recommendation.entity}
                            className={styles.popup}
                            key={recommendation.entity}
                            effect="solid"
                            arrowColor="#FFFFFF00"
                        >
                            {tooltips.find(c => c.label === recommendation.entity).description}
                        </ReactTooltip>
                    )}
                </div>
                <div className={styles.detailItem}>
                    <div className={styles.label}>Summary of entity response:</div>
                    <div className={styles.value}>{recommendation.response}</div>
                </div>
            </div>
            {isMobile && (
                <div className={styles.moreDetail}>
                    <button className={styles.button} onClick={handleExpand}>
                        {expanded ? "Less detail -" : "More detail +"}
                    </button>
                </div>
            )}
        </div>
    );
}
