import React from "react";
import styles from "./summary-dropdown.module.scss";
import { useIsMobile } from "../../../hooks/mobile.js";
import Select from "react-select";

export default function SummaryDropdown(props) {
    const { value, label, handleChange, placeholder, options, ariaLabel } = props;
    const isMobile = useIsMobile();

    return (
        <div className={styles.summaryDropdown}>
            <span className={styles.label} id={ariaLabel}>
                {label}:
            </span>
            <Select
                aria-labelledby={ariaLabel}
                options={options}
                onChange={handleChange}
                placeholder={placeholder}
                defaultValue={{ value: value, label: value }}
                value={{ value: value, label: value }}
                styles={{
                    control: provided => ({
                        ...provided,
                        borderColor: "#E3E4E6",
                    }),
                    container: provided => ({
                        ...provided,
                        width: isMobile ? "100%" : "250px",
                    }),
                }}
            />
        </div>
    );
}
