import React from "react";
import styles from "./chart.module.scss";

export default function Chart(props) {
    const { size, statuses } = props;
    const [statusesTransformed, setStatusesTransformed] = React.useState(null);

    React.useEffect(() => {
        if (statuses) {
            const s = statuses.filter(d => d.percent > 0);
            setStatusesTransformed(s);
        }
    }, [statuses]);

    return (
        <div>
            {statusesTransformed && statusesTransformed.length > 0 && (
                <div
                    className={styles.chart}
                    data-size={size}
                    style={{
                        gridTemplateColumns: statusesTransformed.map(s => s.percent + "% ").join(" "),
                        display: "grid",
                        margin: "10px 0",
                    }}
                >
                    {statusesTransformed.find(d => d.status === "Fully implemented") !== undefined && (
                        <span className={styles.bar} data-status="Fully implemented">
                            {statusesTransformed.find(d => d.status === "Fully implemented").recommendations} fully
                            implemented
                        </span>
                    )}
                    {statusesTransformed.find(d => d.status === "Partially implemented") !== undefined && (
                        <span className={styles.bar} data-status="Partially implemented">
                            {statusesTransformed.find(d => d.status === "Partially implemented").recommendations}{" "}
                            partially implemented
                        </span>
                    )}
                    {statusesTransformed.find(d => d.status === "Not implemented") !== undefined && (
                        <span className={styles.bar} data-status="Not implemented">
                            {statusesTransformed.find(d => d.status === "Not implemented").recommendations} not
                            implemented
                        </span>
                    )}
                    {statusesTransformed.find(d => d.status === "No longer applicable") !== undefined && (
                        <span className={styles.bar} data-status="No longer applicable">
                            {statusesTransformed.find(d => d.status === "No longer applicable").recommendations}no
                            longer applicable
                        </span>
                    )}
                </div>
            )}
            {statusesTransformed && statusesTransformed.length === 0 && (
                <div
                    className={styles.chart}
                    data-size={size}
                    style={{
                        gridTemplateColumns: "1fr",
                        display: "grid",
                        margin: "10px 0",
                    }}
                >
                    <span className={styles.bar} data-status="No recommendations">
                        No recommendations fell into this category
                    </span>
                </div>
            )}
        </div>
    );
}
