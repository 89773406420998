import React from "react";
import styles from "./chart-percent.module.scss";

export default function ChartPercent(props) {
    const { status } = props;

    const [statusChecked, setStatusChecked] = React.useState(false);

    React.useEffect(() => {
        if (status) {
            const s = {
                ...status,
                percent: isNaN(status.percent) ? "-" : status.percent,
            };
            setStatusChecked(s);
        }
    }, [status]);

    return (
        <div className={styles.chartPercentContainer}>
            <div
                className={styles.chart}
                style={{
                    gridTemplateColumns:
                        statusChecked.percent === "-"
                            ? "1fr"
                            : statusChecked.percent + "fr " + (100 - statusChecked.percent) + "fr ",
                }}
            >
                {statusChecked.percent !== "-" && (
                    <span
                        className={styles.bar}
                        data-status={statusChecked.status}
                    >{`${statusChecked.recommendations}  ${statusChecked.status}`}</span>
                )}
                <span className={styles.bar} data-status="other">
                    Other statuses: {100 - statusChecked.percent}
                </span>
            </div>
            <div
                className={styles.percent}
                data-status={statusChecked.status}
                title={statusChecked.percent === "-" ? "No recommendations fell into this category" : ""}
            >
                {statusChecked.percent !== "-" ? `${statusChecked.percent}%` : "NA"}
            </div>
        </div>
    );
}
