import React from "react";
import styles from "./categories.module.scss";
import { useIsMobile } from "../../../../hooks/mobile.js";
import SummaryDropdown from "../../summary-dropdown/summary-dropdown";
import Chart from "../../../chart/chart";
import ChartPercent from "../../../chart-percent/chart-percent";
import { STATUS_ORDER } from "../../../../config/constants.js";
import ReactTooltip from "react-tooltip";

// hello. There are duplicates of the category list because I decided to use grid for desktop
// but then REALLY needed flex for mobile and had to change the html too.

export default function Categories(props) {
    const { byCategory, auditYear, statusOptions, handleChangeStatus, status, tooltips } = props;
    const isMobile = useIsMobile();

    return (
        <div className={styles.categories}>
            <span className={styles.labelSmall}>Categories</span>
            <div className={styles.descriptionAndFilter}>
                <div className={styles.description}>
                    We group our recommendations into categories that reflect the most common types we made to entities.
                    We acknowledge that some of the categories, like risk management, form part of governance.
                </div>
                <div className={styles.dropdown}>
                    <SummaryDropdown
                        options={statusOptions}
                        placeholder="All statuses"
                        value={status}
                        label="Filter by status"
                        handleChange={handleChangeStatus}
                        ariaLabel="filter-by-status"
                    />
                </div>
            </div>
            <div className={styles.categoryList}>
                {tooltips &&
                    byCategory
                        .filter(d => d.auditYear === auditYear && d.status === status)
                        .map(d => (
                            <div
                                key={d.category}
                                className={styles.categoryItem}
                                data-percentchart={status !== "All statuses" ? "true" : "false"}
                            >
                                <span className={styles.categoryNumber}>{d.recommendations}</span>
                                <ReactTooltip
                                    aria-haspopup="true"
                                    type="light"
                                    id={d.category}
                                    className={styles.popup}
                                    key={d.category}
                                    effect="solid"
                                    arrowColor="#FFFFFF00"
                                >
                                    {tooltips.find(c => c.label === d.category).description}
                                </ReactTooltip>
                                <div className={styles.categoryContainer}>
                                    <span className={styles.categoryLabelContainer}>
                                        <span
                                            className={styles.categoryLabel}
                                            data-tip={d.category}
                                            data-for={d.category}
                                        >
                                            {d.category}
                                        </span>
                                    </span>
                                    <span className={styles.chartContainer}>
                                        {status === "All statuses" && (
                                            <Chart
                                                size="tiny"
                                                statuses={STATUS_ORDER.map(e => {
                                                    return byCategory
                                                        .filter(
                                                            f =>
                                                                f.auditYear === auditYear &&
                                                                f.category === d.category &&
                                                                f.status === e
                                                        )
                                                        .map(g => {
                                                            return {
                                                                percent: (
                                                                    (g.recommendations /
                                                                        byCategory.find(
                                                                            h =>
                                                                                h.auditYear === auditYear &&
                                                                                h.status === "All statuses" &&
                                                                                h.category === d.category
                                                                        ).recommendations) *
                                                                    100
                                                                ).toFixed(0),
                                                                recommendations: g.recommendations,
                                                                status: e,
                                                            };
                                                        })
                                                        .flat();
                                                }).flat()}
                                            />
                                        )}
                                        {status !== "All statuses" && (
                                            <ChartPercent
                                                status={
                                                    byCategory
                                                        .filter(
                                                            e =>
                                                                e.auditYear === auditYear &&
                                                                e.category === d.category &&
                                                                e.status === status
                                                        )
                                                        .map(g => {
                                                            return {
                                                                percent: (
                                                                    (g.recommendations /
                                                                        byCategory.find(
                                                                            h =>
                                                                                h.auditYear === auditYear &&
                                                                                h.status === "All statuses" &&
                                                                                h.category === d.category
                                                                        ).recommendations) *
                                                                    100
                                                                ).toFixed(0),
                                                                recommendations: g.recommendations,
                                                                status: status,
                                                                total: byCategory.find(
                                                                    h =>
                                                                        h.auditYear === auditYear &&
                                                                        h.status === "All statuses" &&
                                                                        h.category === d.category
                                                                ).recommendations,
                                                            };
                                                        })[0]
                                                }
                                            />
                                        )}
                                    </span>
                                </div>
                            </div>
                        ))}
            </div>
        </div>
    );
}
