import React from "react";
import ReactGA from "react-ga";
import "./App.css";
import useRecommendationsData from "./hooks/use-recommendations-data";
import Summary from "./components/summary/summary";
import Explore from "./components/explore/explore";
import Intro from "./components/intro/intro";
import SummaryDropdown from "./components/summary/summary-dropdown/summary-dropdown";
import { ENTITY_TYPES, STATUS_ORDER } from "./config/constants.js";

const AUDIT_YEAR_ALL = "All audit years";
const STATUS_ALL = "All statuses";

ReactGA.initialize("UA-12084678-1");

function App() {
    const { recommendationsData, tooltipsData } = useRecommendationsData();
    const [recommendations, setRecommendations] = React.useState(null);
    const [summaryData, setSummaryData] = React.useState(null);
    const [auditYear, setAuditYear] = React.useState(AUDIT_YEAR_ALL);
    const [status, setStatus] = React.useState(STATUS_ALL);
    const [selectedEntityType, setSelectedEntityType] = React.useState("report");
    const [filteredRecommendations, setFilteredRecommendations] = React.useState(null);
    const [categorySortOrder, setCategorySortOrder] = React.useState(null);
    const [tooltips, setTooltips] = React.useState(null);

    // options
    const [auditYearOptions, setAuditYearOptions] = React.useState(null);
    const [statusOptions, setStatusOptions] = React.useState(null);
    const [categoriesOptions, setCategoriesOptions] = React.useState(null);
    const [reportsOptions, setReportsOptions] = React.useState(null);
    const [parliamentaryCommitteesOptions, setParliamentaryCommitteesOptions] = React.useState(null);
    const [departmentsOptions, setDepartmentsOptions] = React.useState(null);
    const [hospitalAndHealthServicesOptions, setHospitalAndHealthServicesOptions] = React.useState(null);
    const [otherStateEntitiesOptions, setOtherStateEntitiesOptions] = React.useState(null);
    const [councilsOptions, setCouncilsOptions] = React.useState(null);

    // filters
    const [filtersApplied, setFiltersApplied] = React.useState(0);
    const [filterAuditYear, setFilterAuditYear] = React.useState(null);
    const [filterCategories, setFilterCategories] = React.useState(null);
    const [filterReports, setFilterReports] = React.useState(null);
    const [filterParliamentaryCommittees, setFilterParliamentaryCommittees] = React.useState(null);
    const [filterDepartments, setFilterDepartments] = React.useState(null);
    const [filterHospitalAndHealthServices, setFilterHospitalAndHealthServices] = React.useState(null);
    const [filterOtherStateEntities, setFilterOtherStateEntities] = React.useState(null);
    const [filterCouncils, setFilterCouncils] = React.useState(null);
    const [filterStatus, setFilterStatus] = React.useState(null);

    const handleScrollToSummary = () => {
        const element = document.querySelector("#qao-recommendations-dashboard-summary");
        window.scrollTo({ top: element.offsetTop - 30, behaviour: "smooth" });
    };
    const handleScrollToExplore = () => {
        const element = document.querySelector("#qao-recommendations-dashboard-explore");
        window.scrollTo({ top: element.offsetTop - 30, behaviour: "smooth" });
    };

    const handleFilterStatusChange = e => {
        // if e is in the filterStatus, take it out. otherwise, put it in.
        const fs = filterStatus;
        let newFilter = fs.find(d => d.value === e);
        if (newFilter) {
            newFilter = fs.filter(d => d.value !== e);
            ReactGA.event({
                category: "RECS: filter",
                action: "status off",
                label: e,
            });
        } else {
            newFilter = [...fs, { value: e, label: e }];
            ReactGA.event({
                category: "RECS: filter",
                action: "status on",
                label: e,
            });
        }

        handleFilterChange(newFilter, "statuses");
    };

    const handleFilterChange = (filter, type, resetAuditYear = true) => {
        // since the state might not have been updated yet, and we don't know which one is being changed
        let _filterAuditYear = filterAuditYear;
        let _filterCategories = filterCategories;
        let _filterReports = filterReports;
        let _filterParliamentaryCommittees = filterParliamentaryCommittees;
        let _filterDepartments = filterDepartments;
        let _filterHospitalAndHealthServices = filterHospitalAndHealthServices;
        let _filterOtherStateEntities = filterOtherStateEntities;
        let _filterCouncils = filterCouncils;
        let _filterStatus = filterStatus;

        if (filter.length > 0) {
            ReactGA.event({
                category: "RECS: filter",
                action: type,
                label: filter.map(d => d.value),
            });
        }

        switch (type) {
            case "audit-years":
                setFilterAuditYear(filter);
                _filterAuditYear = filter;

                // also reset the audit year dropdown?
                if (resetAuditYear) {
                    handleChangeAuditYear({ value: AUDIT_YEAR_ALL, label: AUDIT_YEAR_ALL }, false);
                }
                break;
            case "categories":
                setFilterCategories(filter);
                _filterCategories = filter;
                break;
            case "reports":
                setFilterReports(filter);
                _filterReports = filter;
                break;
            case "parliamentary-committees":
                setFilterParliamentaryCommittees(filter);
                _filterParliamentaryCommittees = filter;
                break;
            case "departments":
                setFilterDepartments(filter);
                _filterDepartments = filter;
                break;
            case "hhs":
                setFilterHospitalAndHealthServices(filter);
                _filterHospitalAndHealthServices = filter;
                break;
            case "other-state-entities":
                setFilterOtherStateEntities(filter);
                _filterOtherStateEntities = filter;
                break;
            case "councils":
                setFilterCouncils(filter);
                _filterCouncils = filter;
                break;
            case "statuses":
                setFilterStatus(filter);
                _filterStatus = filter;
                break;
            default:
                console.log("filter", filter);
                console.log("type", type);
        }

        // do the filtering
        let filtersOn = [
            _filterAuditYear,
            _filterCategories,
            _filterReports,
            _filterParliamentaryCommittees,
            _filterDepartments,
            _filterHospitalAndHealthServices,
            _filterOtherStateEntities,
            _filterCouncils,
        ].filter(d => d && d.length > 0);

        filtersOn = [0, 4].includes(_filterStatus.length) ? filtersOn.length : filtersOn.length + 1;
        setFiltersApplied(filtersOn);

        const recs = recommendations
            .filter(d => {
                return _filterAuditYear && _filterAuditYear.length > 0
                    ? _filterAuditYear.map(e => e.value).includes(d.auditYear)
                    : true;
            })
            .filter(d => {
                return _filterCategories && _filterCategories.length > 0
                    ? _filterCategories.map(e => e.value).some(e => d.categories.includes(e))
                    : true;
            })
            .filter(d => {
                return _filterReports && _filterReports.length > 0
                    ? _filterReports.map(e => e.value).includes(d.report)
                    : true;
            })
            .filter(d => {
                return _filterParliamentaryCommittees && _filterParliamentaryCommittees.length > 0
                    ? _filterParliamentaryCommittees.map(e => e.value).includes(d.parliamentaryCommittee)
                    : true;
            })
            .filter(d => {
                return _filterDepartments && _filterDepartments.length > 0
                    ? _filterDepartments.map(e => e.value).includes(d.department)
                    : true;
            })
            .filter(d => {
                return _filterHospitalAndHealthServices && _filterHospitalAndHealthServices.length > 0
                    ? _filterHospitalAndHealthServices.map(e => e.value).includes(d.hhs)
                    : true;
            })
            .filter(d => {
                return _filterOtherStateEntities && _filterOtherStateEntities.length > 0
                    ? _filterOtherStateEntities.map(e => e.value).includes(d.otherStateEntity)
                    : true;
            })
            .filter(d => {
                return _filterCouncils && _filterCouncils.length > 0
                    ? _filterCouncils.map(e => e.value).includes(d.council)
                    : true;
            })
            .filter(d => {
                return _filterStatus && _filterStatus.length > 0
                    ? _filterStatus.map(e => e.value).includes(d.status)
                    : true;
            });

        setFilteredRecommendations(recs);
    };

    const handleResetFilters = () => {
        setFilterAuditYear(null);
        setFilterCategories(null);
        setFilterReports(null);
        setFilterParliamentaryCommittees(null);
        setFilterDepartments(null);
        setFilterHospitalAndHealthServices(null);
        setFilterOtherStateEntities(null);
        setFilterCouncils(null);
        setFiltersApplied(0);
        setFilteredRecommendations(recommendations);
        setFilterStatus(
            [...new Set(recommendations.map(d => d.status))].map(d => {
                return { value: d, label: d };
            })
        );
        ReactGA.event({
            category: "RECS: filter",
            action: "reset",
        });
    };

    const handleChangeAuditYear = (year, filterChange = false) => {
        ReactGA.event({
            category: "RECS: summary",
            action: "change audit year",
            label: year.value,
        });
        setAuditYear(year.value);
        if (filterChange) {
            if (year.value === AUDIT_YEAR_ALL) {
                handleFilterChange([], "audit-years", false);
            } else {
                handleFilterChange([{ value: year.value, label: year.value }], "audit-years", false);
            }
        }
    };

    const handleChangeStatus = status => {
        ReactGA.event({
            category: "RECS: summary",
            action: "change status",
            label: status.value,
        });
        setStatus(status.value);
    };

    const handleSelectEntityType = entityType => {
        ReactGA.event({
            category: "RECS: view",
            action: "change view by",
            label: entityType.target.value,
        });
        setSelectedEntityType(entityType.target.value);
    };

    React.useEffect(() => {
        if (tooltipsData) {
            setTooltips(tooltipsData);
        }
    }, [tooltipsData]);

    React.useEffect(() => {
        if (recommendationsData) {
            setRecommendations(recommendationsData);
            setFilteredRecommendations(recommendationsData);

            // the summary has 3 parts:
            // 1. implementation status (by financial year)
            // 2. entites (by financial year)
            // 3. categories (by financial year and implementation status)

            const auditYears = [...new Set(recommendationsData.map(d => d.auditYear)), AUDIT_YEAR_ALL];
            setAuditYearOptions(
                auditYears
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            const statuses = [...new Set(recommendationsData.map(d => d.status)), STATUS_ALL].sort(
                (a, b) => STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b)
            );
            setFilterStatus(
                statuses
                    .filter(d => d !== STATUS_ALL)
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            setStatusOptions(
                statuses.map(d => {
                    return { value: d, label: d };
                })
            );

            const entities = Object.keys(ENTITY_TYPES);
            const categories = [...new Set(recommendationsData.map(d => d.categories).flat())];
            setCategoriesOptions(
                categories
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );

            const statusData = auditYears
                .map(d => {
                    return statuses.map(e => {
                        return {
                            auditYear: d,
                            status: e,
                            recommendations: recommendationsData.filter(
                                r => (d === AUDIT_YEAR_ALL ? true : r.auditYear === d) && r.status === e
                            ).length,
                        };
                    });
                })
                .flat();

            // for each of these types of entities, how many unique entities had recommendations?
            // for all the recommendations, count the unique number of `entityType`
            const entityTypeData = auditYears
                .map(d => {
                    return entities.map(e => {
                        return {
                            auditYear: d,
                            entityType: e,
                            recommendations: [
                                ...new Set(
                                    recommendationsData
                                        .filter(r => (d === AUDIT_YEAR_ALL ? true : r.auditYear === d))
                                        .map(r => r[e])
                                        .filter(d => d !== null)
                                ),
                            ].length,
                        };
                    });
                })
                .flat();

            const categoryData = auditYears
                .map(d => {
                    return categories
                        .map(e => {
                            return statuses.map(f => {
                                return {
                                    auditYear: d,
                                    category: e,
                                    status: f,
                                    recommendations: recommendationsData.filter(
                                        r =>
                                            (d === AUDIT_YEAR_ALL ? true : r.auditYear === d) &&
                                            r.categories.includes(e) &&
                                            (f === STATUS_ALL ? true : r.status === f)
                                    ).length,
                                };
                            });
                        })
                        .flat();
                })
                .flat()
                .sort((a, b) => {
                    if (categorySortOrder === null) {
                        return b.recommendations - a.recommendations;
                    }
                    return categorySortOrder.indexOf(b) - categorySortOrder.indexOf(a);
                });

            if (categorySortOrder === null) {
                const c = categoryData
                    .filter(d => d.status === STATUS_ALL && d.auditYear === AUDIT_YEAR_ALL)
                    .map(d => d.category);
                setCategorySortOrder(c);
            }

            setSummaryData({
                byStatus: statusData,
                byEntityType: entityTypeData,
                byCategory: categoryData,
            });

            setReportsOptions(
                [...new Set(recommendationsData.map(d => d.report).flat())]
                    .filter(d => d !== null)
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            setParliamentaryCommitteesOptions(
                [...new Set(recommendationsData.map(d => d.parliamentaryCommittee).flat())]
                    .filter(d => d !== null)
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            setDepartmentsOptions(
                [...new Set(recommendationsData.map(d => d.department).flat())]
                    .filter(d => d !== null)
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            setHospitalAndHealthServicesOptions(
                [...new Set(recommendationsData.map(d => d.hhs).flat())]
                    .filter(d => d !== null)
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            setOtherStateEntitiesOptions(
                [...new Set(recommendationsData.map(d => d.otherStateEntity).flat())]
                    .filter(d => d !== null)
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
            setCouncilsOptions(
                [...new Set(recommendationsData.map(d => d.council).flat())]
                    .filter(d => d !== null)
                    .sort((a, b) => a.localeCompare(b))
                    .map(d => {
                        return { value: d, label: d };
                    })
            );
        }
    }, [recommendationsData]); // you'll get a warning but don't add categorySortOrder because we only want this to happen once.

    return (
        <div id="root">
            <Intro handleScrollToSummary={handleScrollToSummary} handleScrollToExplore={handleScrollToExplore} />
            {auditYear && auditYearOptions && (
                <SummaryDropdown
                    ariaLabel="filter-by-audit-year"
                    value={auditYear}
                    handleChange={c => handleChangeAuditYear(c, true)}
                    placeholder="All audit years"
                    label="Select an audit year"
                    options={auditYearOptions}
                />
            )}
            {summaryData && auditYear && (
                <Summary
                    summaryData={summaryData}
                    auditYear={auditYear}
                    auditYearOptions={auditYearOptions}
                    statusOptions={statusOptions}
                    handleChangeStatus={handleChangeStatus}
                    status={status}
                    tooltips={tooltips}
                />
            )}
            {recommendations && (
                <Explore
                    recommendations={recommendations}
                    selectedEntityType={selectedEntityType}
                    handleSelectEntityType={handleSelectEntityType}
                    filteredRecommendations={filteredRecommendations}
                    filtersApplied={filtersApplied}
                    filterAuditYear={filterAuditYear}
                    filterCategories={filterCategories}
                    filterReports={filterReports}
                    filterParliamentaryCommittees={filterParliamentaryCommittees}
                    filterDepartments={filterDepartments}
                    filterHospitalAndHealthServices={filterHospitalAndHealthServices}
                    filterOtherStateEntities={filterOtherStateEntities}
                    filterCouncils={filterCouncils}
                    auditYearOptions={auditYearOptions}
                    statusOptions={statusOptions.filter(d => d.label !== STATUS_ALL)}
                    categoriesOptions={categoriesOptions}
                    reportsOptions={reportsOptions}
                    parliamentaryCommitteesOptions={parliamentaryCommitteesOptions}
                    departmentsOptions={departmentsOptions}
                    hospitalAndHealthServicesOptions={hospitalAndHealthServicesOptions}
                    otherStateEntitiesOptions={otherStateEntitiesOptions}
                    councilsOptions={councilsOptions}
                    handleFilterChange={handleFilterChange}
                    handleResetFilters={handleResetFilters}
                    filterStatus={filterStatus}
                    handleFilterStatusChange={handleFilterStatusChange}
                    tooltips={tooltips}
                    auditYear={auditYear}
                />
            )}
        </div>
    );
}

export default App;
