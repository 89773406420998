import React from "react";
import ReactGA from "react-ga";
import styles from "./filters.module.scss";
import { useIsMobile } from "../../../../hooks/mobile.js";
import FilterDropdown from "./filter-dropdown/filter-dropdown";
import StatusFilter from "./status-filter/status-filter";

ReactGA.initialize("UA-12084678-1");

export default function Filters(props) {
    const isMobile = useIsMobile();
    const [expanded, setExpanded] = React.useState(false);
    const {
        filtersApplied,
        filterAuditYear,
        filterCategories,
        filterReports,
        filterParliamentaryCommittees,
        filterDepartments,
        filterHospitalAndHealthServices,
        filterOtherStateEntities,
        filterCouncils,
        filterStatus,
        auditYearOptions,
        statusOptions,
        categoriesOptions,
        reportsOptions,
        parliamentaryCommitteesOptions,
        departmentsOptions,
        hospitalAndHealthServicesOptions,
        otherStateEntitiesOptions,
        councilsOptions,
        handleFilterChange,
        handleResetFilters,
        handleFilterStatusChange,
        mobileFilterExpanded,
        handleMobileFilterExpand,
    } = props;

    const filterRef = React.useRef();

    const handleExpand = () => {
        ReactGA.event({
            category: "RECS: filter",
            action: "expand",
            label: expanded ? "closed" : "expanded",
        });
        setExpanded(!expanded);
    };

    const handleApplyFilters = () => {
        // close and scroll to top of filter
        handleMobileFilterExpand();
        filterRef.current.scrollIntoView();
    };

    React.useEffect(() => {
        if (mobileFilterExpanded) {
            setExpanded(mobileFilterExpanded);
        }
    }, [mobileFilterExpanded]);

    return (
        <div className={styles.filters} ref={filterRef}>
            <h3>Filters</h3>
            <div className={styles.filterHeading}>
                {!isMobile && (
                    <button onClick={handleExpand} className={styles.filterButton}>
                        {!expanded && (
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                className={styles.chevronDown}
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
                                ></path>
                            </svg>
                        )}
                        {expanded && (
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                role="img"
                                className={styles.chevronUp}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
                                ></path>
                            </svg>
                        )}
                        {expanded && <span>Hide filters</span>}
                        {!expanded && <span>Show filters</span>}
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            className={styles.filterIcon}
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M479.968 0H32.038C3.613 0-10.729 34.487 9.41 54.627L192 237.255V424a31.996 31.996 0 0 0 10.928 24.082l64 55.983c20.438 17.883 53.072 3.68 53.072-24.082V237.255L502.595 54.627C522.695 34.528 508.45 0 479.968 0zM288 224v256l-64-56V224L32 32h448L288 224z"
                            ></path>
                        </svg>
                    </button>
                )}
                <div className={styles.appliedReset} data-active={filtersApplied > 0 ? "active" : "inactive"}>
                    {!isMobile && (
                        <span className={styles.filtersApplied}>
                            {filtersApplied} {filtersApplied === 1 ? " filter " : " filters "} applied
                        </span>
                    )}
                    <button className={styles.resetButton} onClick={handleResetFilters}>
                        Reset filters
                    </button>
                    {isMobile && (
                        <button className={styles.applyButton} onClick={handleApplyFilters}>
                            Apply filters
                        </button>
                    )}
                </div>
            </div>
            {expanded && (
                <div className={styles.filterExpanded}>
                    <div className={styles.filterItem} data-filteritem="audit-years">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more audit years..."
                            label="Audit year"
                            value={filterAuditYear}
                            options={auditYearOptions}
                            type="audit-years"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="statuses">
                        <StatusFilter
                            handleChange={handleFilterStatusChange}
                            value={filterStatus}
                            options={statusOptions}
                            type="status"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="categories">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more categories..."
                            label="Categories"
                            value={filterCategories}
                            options={categoriesOptions}
                            type="categories"
                        />
                    </div>

                    <h4>Filter by specific reports and other groupings:</h4>

                    <div className={styles.filterItem} data-filteritem="reports">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more reports..."
                            label="Reports"
                            value={filterReports}
                            options={reportsOptions}
                            type="reports"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="parliamentary-committees">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more parliamentary committees..."
                            label="Parliamentary committees"
                            value={filterParliamentaryCommittees}
                            options={parliamentaryCommitteesOptions}
                            type="parliamentary-committees"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="departments">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more departments..."
                            label="Departments"
                            value={filterDepartments}
                            options={departmentsOptions}
                            type="departments"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="hhs">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more hospital and health services..."
                            label="Hospital and health services"
                            value={filterHospitalAndHealthServices}
                            options={hospitalAndHealthServicesOptions}
                            type="hhs"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="other-state-entities">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more other state entities..."
                            label="Other state entities"
                            value={filterOtherStateEntities}
                            options={otherStateEntitiesOptions}
                            type="other-state-entities"
                        />
                    </div>
                    <div className={styles.filterItem} data-filteritem="councils">
                        <FilterDropdown
                            handleChange={handleFilterChange}
                            placeholder="Select one or more councils..."
                            label="Councils"
                            value={filterCouncils}
                            options={councilsOptions}
                            type="councils"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
