import React from "react";
import styles from "./view.module.scss";
import { ENTITY_TYPES } from "../../../../config/constants.js";

export default function View(props) {
    const { selectedEntityType, handleSelectEntityType } = props;
    const entities = Object.values(ENTITY_TYPES);

    return (
        <div className={styles.view}>
            <h3>View by</h3>
            <div className={styles.viewContainer}>
                <div className={styles.viewEntities}>
                    {entities.map(d => {
                        return (
                            <button
                                className={styles.entityType}
                                key={d.plural}
                                data-active={selectedEntityType === d.key ? "active" : "inactive"}
                                value={d.key}
                                onClick={handleSelectEntityType}
                            >
                                <span className={styles.entityIconContainer}>
                                    <span className={styles.entityIcon}>{d.icon}</span>
                                </span>
                                <span className={styles.entityLabel}>{d.plural}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
