import axios from "axios";
import React from "react";
import { DATA_SHEET, TOOLTIPS_SHEET } from "../config/constants";

const useRecommendationsData = () => {
    const [recommendationsData, setRecommendationsData] = React.useState(null);
    const [tooltipsData, setTooltipsData] = React.useState(null);

    React.useEffect(() => {
        Promise.all([
            axios(DATA_SHEET)
                .then(res => res.data)
                .then(res =>
                    res.map(d => {
                        return {
                            auditYear: d["Audit Report Year"],
                            status: d["Status"],
                            number: d["Rec NO."],
                            recommendation: d["Recommendation"],
                            categories: d["Recommendation Category"].split(";").map(e => e.trim()),
                            response: d["Response"],

                            report: d["Audit Report"],
                            parliamentaryCommittee: d["Parliamentary Committee"],

                            department: d["Entity Category"] === "Department" ? d["Agency Responded"] : null,
                            council: d["Entity Category"] === "Local government" ? d["Agency Responded"] : null,
                            hhs: d["Entity Category"] === "HHS" ? d["Agency Responded"] : null,
                            otherStateEntity: d["Entity Category"] === "Other entity" ? d["Agency Responded"] : null,

                            entity: d["Agency Responded"],

                            id: d["internal_id"],
                        };
                    })
                )
                .then(setRecommendationsData),
            axios(TOOLTIPS_SHEET)
                .then(res => res.data)
                .then(setTooltipsData),
        ]);
    }, []);

    return {
        recommendationsData,
        tooltipsData,
    };
};

export default useRecommendationsData;
