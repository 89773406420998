import React from "react";
import styles from "./report.module.scss";
import Categories from "./categories/categories";
import Entities from "./entities/entities";

const ICON_REPORT = (
    <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        width="43"
        height="52"
        viewBox="0 0 43 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M39.5625 51.3125C41.4265 51.3125 42.9375 49.8015 42.9375 47.9375V18.1408L26.0625 0.6875H4.125C2.26104 0.6875 0.75 2.19854 0.75 4.0625V47.9375C0.75 49.8015 2.26104 51.3125 4.125 51.3125H39.5625ZM26.0627 15.8806V3.11532L40.8469 18.4062H28.5968C27.7576 18.4 27.1364 18.1335 26.7299 17.7314C26.3256 17.3316 26.0571 16.7223 26.0627 15.8806ZM28.5882 20.0938H41.25V47.9375C41.25 48.8695 40.4945 49.625 39.5625 49.625H4.125C3.19303 49.625 2.4375 48.8695 2.4375 47.9375V4.0625C2.4375 3.13053 3.19303 2.375 4.125 2.375H24.3752V15.8724C24.3677 17.0935 24.7672 18.1638 25.5433 18.9314C26.3175 19.6968 27.3897 20.0856 28.5882 20.0938ZM10.7181 15.7864L14.9782 10.8125L16.2598 11.9102L10.6998 18.4019L7.5 14.5586L8.79686 13.4788L10.7181 15.7864ZM14.9782 22.625L10.7181 27.5989L8.79686 25.2913L7.5 26.3711L10.6998 30.2144L16.2598 23.7227L14.9782 22.625ZM14.9782 34.4375L10.7181 39.4114L8.79686 37.1037L7.5 38.1836L10.6998 42.0269L16.2598 35.5352L14.9782 34.4375ZM19.3125 15.875H22.6875V14.1875H19.3125V15.875ZM19.3125 28.5312H36.1875V26.8438H19.3125V28.5312ZM19.3125 38.6562H36.1875V36.9688H19.3125V38.6562Z"
            fill="#363E7C"
        />
    </svg>
);

export default function Report(props) {
    const { byEntityType, byCategory, auditYear, statusOptions, handleChangeStatus, status, tooltips } = props;

    return (
        <div className={styles.reports}>
            <div className={styles.reportsNumber}>
                <div className={styles.reportsNumberIcon}>{ICON_REPORT}</div>
                <div className={styles.reportsNumberLabel}>
                    <span className={styles.number}>
                        {byEntityType.find(d => d.auditYear === auditYear && d.entityType === "report").recommendations}
                    </span>
                    <span className={styles.labelSmall}>reports</span>

                    {auditYear !== "All audit years" && <small>tabled in {auditYear}</small>}
                </div>
            </div>
            <div className={styles.reportsEntitiesCategories}>
                <Entities byEntityType={byEntityType} auditYear={auditYear} />
                <Categories
                    byCategory={byCategory}
                    auditYear={auditYear}
                    statusOptions={statusOptions}
                    handleChangeStatus={handleChangeStatus}
                    status={status}
                    tooltips={tooltips}
                />
            </div>
        </div>
    );
}
