import React from "react";
import ReactGA from "react-ga";
import styles from "./list.module.scss";
import { useIsMobile } from "../../../hooks/mobile.js";
import Group from "./group/group";
import { ENTITY_TYPES } from "../../../config/constants.js";

ReactGA.initialize("UA-12084678-1");

export default function List(props) {
    const { filteredRecommendations, selectedEntityType, filtersApplied, handleResetFilters, tooltips } = props;
    const [groups, setGroups] = React.useState(null);
    const [entityRecommendations, setEntityRecommendations] = React.useState(null);
    const [sortBy, setSortBy] = React.useState("entity");
    const isMobile = useIsMobile();

    React.useEffect(() => {
        const g = [...new Set(filteredRecommendations.map(d => d[selectedEntityType]))].filter(d => d !== null);

        // the filtered recommendations come from the filters.
        // but when an entity is selected, not all filtered recs will pertain to those
        // so it needs to be refiltered.

        const e = filteredRecommendations.filter(d => g.includes(d[selectedEntityType]));

        setEntityRecommendations(e);
        setGroups(g.sort((a, b) => a.localeCompare(b)));
    }, [selectedEntityType, filteredRecommendations]);

    const handleSortBy = sortBy => {
        ReactGA.event({
            category: "RECS: sort",
            action: "sort by",
            label: sortBy,
        });
        setSortBy(sortBy);
        const g = groups;

        if (sortBy === "entity") {
            setGroups(g.sort((a, b) => a.localeCompare(b)));
        } else if (sortBy === "recommendations") {
            setGroups(
                g.sort((a, b) => {
                    const aRecs = filteredRecommendations.filter(d => a === d[selectedEntityType]).length;
                    const bRecs = filteredRecommendations.filter(d => b === d[selectedEntityType]).length;

                    return bRecs - aRecs;
                })
            );
        }
    };

    return (
        <div className={styles.listContainer}>
            <div className={styles.listSortBy}>
                <span className={styles.sortByLabel}>Sort by:</span>
                <div className={styles.sortByButtons}>
                    <button
                        onClick={() => handleSortBy("entity")}
                        className={styles.sortByButton}
                        data-active={sortBy === "entity" ? "active" : "inactive"}
                    >
                        {isMobile ? "Name" : `Name of ${ENTITY_TYPES[selectedEntityType].singular.toLowerCase()}`}
                    </button>
                    <button
                        onClick={() => handleSortBy("recommendations")}
                        className={styles.sortByButton}
                        data-active={sortBy === "recommendations" ? "active" : "inactive"}
                    >
                        Number of recommendations
                    </button>
                </div>
            </div>

            {groups && (
                <div className={styles.listItemsContainer}>
                    <div className={styles.listHeader}>
                        <div className={styles.listHeaderSummary}>
                            <span className={styles.headerContainer}>
                                <span className={styles.summaryEntities}>
                                    {groups.length}{" "}
                                    {groups.length === 1
                                        ? ENTITY_TYPES[selectedEntityType].singular
                                        : ENTITY_TYPES[selectedEntityType].plural}{" "}
                                </span>
                                <span className={styles.summaryRecommendations}>
                                    {entityRecommendations.length}{" "}
                                    {entityRecommendations.length === 1 ? " recommendation" : "recommendations"}
                                </span>
                                {filtersApplied > 0 && (
                                    <span className={styles.filteredNumber}>
                                        {filtersApplied} {filtersApplied === 1 ? " filter applied" : "filters applied"}
                                    </span>
                                )}
                            </span>
                            {selectedEntityType === "parliamentaryCommittee" && (
                                <span className={styles.parliamentaryCommitteeDescription}>
                                    While we have linked particular reports and recommendations to relevant
                                    parliamentary committees, some may be relevant to one or more committees.
                                </span>
                            )}
                        </div>
                        <div className={styles.listHeaderFully}>% Fully implemented</div>
                        <div className={styles.listHeaderNumber}>Number of recommendations</div>
                    </div>
                    {groups.map(d => {
                        return (
                            <Group
                                selectedEntityType={selectedEntityType}
                                entity={d}
                                key={d}
                                entityRecommendations={entityRecommendations.filter(e => e[selectedEntityType] === d)}
                                tooltips={tooltips}
                            />
                        );
                    })}
                    {groups.length === 0 && (
                        <div className={styles.noResults}>
                            Sorry, there are no results with those filters. Please{" "}
                            <button className={styles.noResultsReset} onClick={handleResetFilters}>
                                reset the filters to start again
                            </button>
                            .
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
