import React from "react";
import styles from "./intro.module.scss";
import { useIsMobile } from "../../hooks/mobile.js";

export default function Intro(props) {
    const { handleScrollToSummary, handleScrollToExplore } = props;
    const isMobile = useIsMobile();

    return (
        <div className={styles.intro}>
            <h1 className={styles.introHeading}>Status of Auditor-General's recommendations dashboard</h1>
            <hr className={styles.headingLine} />
            <p className={styles.introPar}>
                The Queensland Audit Office puts forward better ways for public sector and local government entities to
                deliver public services for Queenslanders. This interactive dashboard allows you to explore entities’
                self-assessed progress in implementing the recommendations we make in our reports to parliament. You can
                tailor your search by year, report, entity, parliamentary committee, and implementation status. It
                reflects entities’ responses for the period in which they completed their self-assessments.
            </p>
            {isMobile && (
                <span className={styles.jumpTo}>
                    Jump to:{" "}
                    <button className={styles.summary} onClick={handleScrollToSummary}>
                        Summary
                    </button>{" "}
                    or{" "}
                    <button className={styles.exploreAll} onClick={handleScrollToExplore}>
                        Explore all
                    </button>
                    .
                </span>
            )}
        </div>
    );
}
