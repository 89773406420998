import React from "react";
import ReactGA from "react-ga";
import styles from "./group.module.scss";
import { useIsMobile } from "../../../../hooks/mobile.js";
import Item from "./item/item";
import { ENTITY_TYPES, STATUS_ORDER } from "../../../../config/constants.js";
import Chart from "../../../chart/chart";

ReactGA.initialize("UA-12084678-1");

export default function Group(props) {
    const { selectedEntityType, entity, entityRecommendations, tooltips } = props;
    const [expanded, setExpanded] = React.useState(false);
    const [reports, setReports] = React.useState(null);
    const isMobile = useIsMobile();

    const handleExpand = () => {
        ReactGA.event({
            category: "RECS: explore",
            action: expanded ? "closed" : "expanded",
            label: entity,
        });
        setExpanded(!expanded);
    };

    // get the set of reports from the recommendations, and sort by name.
    React.useEffect(() => {
        if (entityRecommendations) {
            const r = [...new Set(entityRecommendations.map(d => d.report))].sort((a, b) => a.localeCompare(b));
            setReports(r);
        }
    }, [entityRecommendations]);

    return (
        <div className={styles.group}>
            <button
                className={styles.itemExpando}
                onClick={handleExpand}
                aria-label={`Open this ${selectedEntityType} for its list of recommendations`}
            >
                {!expanded && (
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        className={styles.chevronDown}
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path
                            fill="currentColor"
                            d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
                        ></path>
                    </svg>
                )}
                {expanded && (
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        className={styles.chevronUp}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path
                            fill="currentColor"
                            d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
                        ></path>
                    </svg>
                )}
            </button>
            <div className={styles.groupContainer}>
                <div className={styles.groupCollapsed}>
                    <h4 className={styles.groupHeading} onClick={handleExpand}>
                        <span className={styles.headingIcon}>{ENTITY_TYPES[selectedEntityType].icon}</span>
                        <span className={styles.headingLabel}>{entity}</span>
                    </h4>
                    <div className={styles.groupStats}>
                        <div className={styles.groupStatsChart}>
                            <Chart
                                size="small"
                                statuses={STATUS_ORDER.map(d => {
                                    return {
                                        percent: (
                                            (entityRecommendations.filter(e => e.status === d).length /
                                                entityRecommendations.length) *
                                            100
                                        ).toFixed(0),
                                        recommendations: entityRecommendations.filter(e => e.status === d).length,
                                        status: d,
                                    };
                                })}
                            />
                            {!isMobile && expanded && (
                                <div className={styles.auditYears}>
                                    <span className={styles.auditYears}>
                                        Audit year
                                        {[...new Set(entityRecommendations.map(d => d.auditYear))].length === 1
                                            ? ":"
                                            : "s:"}
                                    </span>
                                    <span className={styles.auditYearsList}>
                                        {[...new Set(entityRecommendations.map(d => d.auditYear))].map(d => (
                                            <span className={styles.year} key={d}>
                                                {d}
                                            </span>
                                        ))}
                                    </span>
                                </div>
                            )}
                        </div>
                        {!expanded && (
                            <div className={styles.collapsedDetails}>
                                <div className={styles.groupStatsFully}>
                                    <span className={styles.value}>
                                        {`${(
                                            (entityRecommendations.filter(d => d.status === "Fully implemented")
                                                .length /
                                                entityRecommendations.length) *
                                            100
                                        ).toFixed(0)}%`}
                                    </span>
                                    <span className={styles.label}>fully implemented</span>
                                </div>
                                <div className={styles.groupStatsRecommendations}>
                                    <span className={styles.value}>{entityRecommendations.length}</span>
                                    <span className={styles.label}>recommendations</span>
                                </div>
                            </div>
                        )}
                        {expanded && (
                            <div className={styles.expandedDetails}>
                                <div className={styles.expandedDetailItem}>
                                    <span className={styles.label} data-status="recommendations">
                                        {entityRecommendations.length}
                                    </span>
                                    <span className={styles.value}>
                                        {entityRecommendations.length === 1 ? "recommendation" : "recommendations"}
                                    </span>
                                </div>
                                {STATUS_ORDER.map(d => {
                                    return entityRecommendations.filter(e => e.status === d).length > 0 ? (
                                        <div className={styles.expandedDetailItem} key={d}>
                                            <span className={styles.label} data-status={d}>
                                                {`${(
                                                    (entityRecommendations.filter(e => e.status === d).length /
                                                        entityRecommendations.length) *
                                                    100
                                                ).toFixed(0)}%`}
                                            </span>
                                            <span className={styles.value}>{d}</span>
                                        </div>
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    );
                                })}
                                {isMobile && (
                                    <div className={styles.auditYears}>
                                        <span className={styles.auditYears}>
                                            Audit year
                                            {[...new Set(entityRecommendations.map(d => d.auditYear))].length === 1
                                                ? ":"
                                                : "s:"}
                                        </span>
                                        <span className={styles.auditYearsList}>
                                            {[...new Set(entityRecommendations.map(d => d.auditYear))].map(d => (
                                                <span className={styles.year} key={d}>
                                                    {d}
                                                </span>
                                            ))}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/*for report, show items*/}
                {selectedEntityType === "report" && expanded && (
                    <div className={styles.groupExpanded}>
                        <div className={styles.groupExpandedSummary}>
                            {entityRecommendations.length}{" "}
                            {entityRecommendations.length === 1 ? "recommendation" : "recommendations"}
                        </div>
                        <div className={styles.itemsContainer}>
                            {entityRecommendations
                                .sort((a, b) => {
                                    const byNumber = a.number > b.number ? 1 : a.number < b.number ? -1 : 0;
                                    const byName = a.report.localeCompare(b.report);
                                    return byName || byNumber;
                                })
                                .map(d => (
                                    <Item
                                        recommendation={d}
                                        tooltips={tooltips}
                                        selectedEntityType={selectedEntityType}
                                    />
                                ))}
                        </div>
                    </div>
                )}

                {/*but for other entity types, split by report*/}

                {selectedEntityType !== "report" && expanded && (
                    <div className={styles.groupExpanded}>
                        <div className={styles.groupExpandedSummary}>
                            {entityRecommendations.length}{" "}
                            {entityRecommendations.length === 1 ? "recommendation" : "recommendations"}
                        </div>
                        {reports.map(r => (
                            <div className={styles.reportGroup} key={r}>
                                <h5 className={styles.reportGroupHeading}>Report: {r}</h5>
                                <div className={styles.itemsContainer}>
                                    {entityRecommendations
                                        .filter(d => d.report === r)
                                        .sort((a, b) => {
                                            const byNumber = a.number > b.number ? 1 : a.number < b.number ? -1 : 0;
                                            const byName = a.report.localeCompare(b.report);
                                            return byName || byNumber;
                                        })
                                        .map(d => (
                                            <Item
                                                recommendation={d}
                                                tooltips={tooltips}
                                                selectedEntityType={selectedEntityType}
                                            />
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
