import React from "react";
import ReactDOM from "react-dom";
import Embed from "./App"; // or where ever the root is

function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

window.addEventListener("DOMContentLoaded", () => {
    if (getEl("[data-recommendations-dashboard-embed]")) {
        ReactDOM.render(<Embed />, getEl("[data-recommendations-dashboard-embed]"));
    }
});
