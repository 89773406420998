import React from "react";
import styles from "./entities.module.scss";
import { ENTITY_TYPES } from "../../../../config/constants.js";

export default function Entities(props) {
    const { byEntityType, auditYear } = props;

    return (
        <div className={styles.entities}>
            <span className={styles.labelSmall}>Entities and committees</span>
            <div className={styles.entityList}>
                {byEntityType
                    .filter(d => d.auditYear === auditYear && d.entityType !== "report")
                    .map(d => (
                        <div className={styles.entityType} key={d.entityType} data-entitytype={d.entityType}>
                            <div className={styles.entityIcon}>{ENTITY_TYPES[d.entityType].icon}</div>
                            <div className={styles.entityName}>
                                {d.recommendations === 1
                                    ? ENTITY_TYPES[d.entityType].singular
                                    : ENTITY_TYPES[d.entityType].plural}
                            </div>
                            <div className={styles.entityNumber}>{d.recommendations}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
