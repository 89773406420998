import React from "react";
import styles from "./view-filter.module.scss";
import { useIsMobile } from "../../../hooks/mobile.js";
import Filters from "./filters/filters";
import View from "./view/view";

export default function ViewFilter(props) {
    const {
        selectedEntityType,
        handleSelectEntityType,
        filtersApplied,
        filterAuditYear,
        filterCategories,
        filterReports,
        filterParliamentaryCommittees,
        filterDepartments,
        filterHospitalAndHealthServices,
        filterOtherStateEntities,
        filterCouncils,
        filterStatus,
        auditYearOptions,
        statusOptions,
        categoriesOptions,
        reportsOptions,
        parliamentaryCommitteesOptions,
        departmentsOptions,
        hospitalAndHealthServicesOptions,
        otherStateEntitiesOptions,
        councilsOptions,
        handleFilterChange,
        handleResetFilters,
        handleFilterStatusChange,
    } = props;
    const [expanded, setExpanded] = React.useState(false);
    const isMobile = useIsMobile();

    const handleMobileFilterExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={styles.viewFilterContainer}>
            {isMobile && (
                <button onClick={handleMobileFilterExpand} className={styles.viewExpando}>
                    {!expanded && (
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            className={styles.chevronDown}
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
                            ></path>
                        </svg>
                    )}
                    {expanded && (
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            className={styles.chevronUp}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                fill="currentColor"
                                d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
                            ></path>
                        </svg>
                    )}
                    {expanded && <span>Hide view and filter options</span>}
                    {!expanded && <span>Show view and filter options</span>}
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        className={styles.filterIcon}
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M479.968 0H32.038C3.613 0-10.729 34.487 9.41 54.627L192 237.255V424a31.996 31.996 0 0 0 10.928 24.082l64 55.983c20.438 17.883 53.072 3.68 53.072-24.082V237.255L502.595 54.627C522.695 34.528 508.45 0 479.968 0zM288 224v256l-64-56V224L32 32h448L288 224z"
                        ></path>
                    </svg>
                </button>
            )}
            {isMobile && (
                <div className={styles.appliedReset} data-active={filtersApplied > 0 ? "active" : "inactive"}>
                    <span className={styles.filtersApplied}>
                        {filtersApplied} {filtersApplied === 1 ? " filter " : " filters "} applied
                    </span>
                    <button className={styles.resetButton} onClick={handleResetFilters}>
                        Reset filters
                    </button>
                </div>
            )}
            {(!isMobile || expanded) && (
                <React.Fragment>
                    <View selectedEntityType={selectedEntityType} handleSelectEntityType={handleSelectEntityType} />
                    <Filters
                        filtersApplied={filtersApplied}
                        filterAuditYear={filterAuditYear}
                        filterCategories={filterCategories}
                        filterReports={filterReports}
                        filterParliamentaryCommittees={filterParliamentaryCommittees}
                        filterDepartments={filterDepartments}
                        filterHospitalAndHealthServices={filterHospitalAndHealthServices}
                        filterOtherStateEntities={filterOtherStateEntities}
                        filterCouncils={filterCouncils}
                        auditYearOptions={auditYearOptions}
                        statusOptions={statusOptions}
                        categoriesOptions={categoriesOptions}
                        reportsOptions={reportsOptions}
                        parliamentaryCommitteesOptions={parliamentaryCommitteesOptions}
                        departmentsOptions={departmentsOptions}
                        hospitalAndHealthServicesOptions={hospitalAndHealthServicesOptions}
                        otherStateEntitiesOptions={otherStateEntitiesOptions}
                        councilsOptions={councilsOptions}
                        handleFilterChange={handleFilterChange}
                        handleResetFilters={handleResetFilters}
                        filterStatus={filterStatus}
                        handleFilterStatusChange={handleFilterStatusChange}
                        mobileFilterExpanded={expanded}
                        handleMobileFilterExpand={handleMobileFilterExpand}
                    />
                </React.Fragment>
            )}
        </div>
    );
}
